import * as React from "react";
import Layout from "../components/Layout.js";

const Wundar = () => {
    return (
      <Layout>
          <p>Wundar</p>
         
      </Layout>

      );

};

export default Wundar;
